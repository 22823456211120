import { Fragment } from 'react'
import type { orderItem as OrderItemType } from './VerificationType'
import { Grid, Typography, Divider, Paper } from '@mui/material'
import { formatPrice } from '../../helpers/format'
const VerificationChangeItem = ({ orderItem }: { orderItem: OrderItemType }) => {
  let totalSubscription: number = 0
  let totalOnceoff: number = 0

  orderItem.product_charges.charges.forEach(charge => {
    if (charge.amounts.subscription_fee) {
      totalSubscription += +charge.amounts.subscription_fee
    }
    if (charge.amounts.adhoc_fee) {
      totalOnceoff += +charge.amounts.adhoc_fee
    }
    charge.discounts.forEach(discount => {
      if (discount.charge_type === 'SUBSCRIPTION_FEE') {
        totalSubscription -= +discount.discount_amount
      }
    })
  });

  const baseCharges = orderItem.product_charges.charges.filter(charge => {
    return charge.charge_name === 'Base charges'
  })

  const baseSubscriptionDiscount = baseCharges[0].discounts.filter(discount => {
    return discount.charge_type === 'SUBSCRIPTION_FEE'
  })

  const otherCharges = orderItem.product_charges.charges.filter(charge => {
    return charge.charge_name !== 'Base charges'
  })

  return <Grid item xs={12} sx={{ mt: '10px', mb: '40px' }} key={orderItem.order_item_id}>
    <Paper variant='outlined'>
      <Grid container sx={{ p: 1 }}>
        <Grid item xs={6} sx={{ display: 'flex', pt: 1, pl: 2, overflow: 'auto' }}>
          <Typography variant='h6' sx={{ color: '#0185FE' }}>{orderItem.change_service_reference}</Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', pt: 1 }} alignItems='flex-end' justifyContent='flex-end'>
          <Typography variant="subtitle1" sx={{ color: '#0185FE' }}>SUBSCRIPTION</Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', pt: 1, '@media (min-width: 600px)': { pr: '25px' } }} alignItems='flex-end' justifyContent='flex-end'>
          <Typography variant="subtitle1" sx={{ color: '#0185FE' }}>ONCE-OFF</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ borderColor: '#989898' }} />
      <Grid container sx={{ p: 2 }}>
        {orderItem.change_plan_id !== orderItem.plan_id && <><Grid item xs={6} sx={{ pl: 1 }}>
          <Typography sx={{ fontWeight: 700, display: 'inline' }}>{orderItem.change_plan_name}</Typography>
          <Typography sx={{ display: 'inline' }}> to </Typography>
          <Typography sx={{ fontWeight: 700, display: 'inline' }}>{orderItem.plan_name}</Typography>
          {baseCharges[0].recurring_month && <Typography sx={{ display: 'inline' }}> - {baseCharges[0].recurring_month} month(s) Subscription</Typography>}
        </Grid>
          <Grid item xs={3} sx={{ display: 'flex' }} justifyContent='flex-end'>
            {baseCharges[0].amounts.subscription_fee && <Typography>${baseCharges[0].amounts.subscription_fee}</Typography>
            }
          </Grid></>
        }
        {orderItem.change_plan_id === orderItem.plan_id && <><Grid item xs={6} sx={{ pl: 1 }}>
          <Typography sx={{ fontWeight: 700, display: 'inline' }}>{orderItem.plan_name}</Typography>
          {baseCharges[0].recurring_month && <Typography sx={{ display: 'inline' }}> - {baseCharges[0].recurring_month} month(s) Subscription</Typography>}
        </Grid>
          <Grid item xs={3} sx={{ display: 'flex' }} justifyContent='flex-end'>
            {baseCharges[0].amounts.subscription_fee && <Typography>${baseCharges[0].amounts.subscription_fee}</Typography>
            }
          </Grid></>
        }
        {baseCharges[0].discounts.length > 0 && <>
          <Grid item xs={12} sx={{ pt: 2 }}><Divider /></Grid>
          <Grid item xs={6} sx={{ pl: 1, pt: 2 }}>
            <Typography sx={{ display: 'inline', color: '#cf0000' }}>Discount</Typography>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', pt: 2 }} justifyContent='flex-end' alignItems='flex-end'>
            {baseSubscriptionDiscount.length > 0 && <Typography sx={{ color: '#cf0000' }}>-${baseSubscriptionDiscount[0].discount_amount}</Typography>}
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', '@media (min-width: 600px)': { pr: '25px' }, pt: 2 }} justifyContent='flex-end' alignItems='flex-end'>
          </Grid>

        </>}
        {otherCharges.map(charge => {
          return <Fragment key={charge.order_item_charge_id}>
            <Grid item xs={12} sx={{ pt: 2 }}><Divider /></Grid>
            <Grid item xs={6} sx={{ pl: 1, pt: 2 }}>
              <Typography sx={{ display: 'inline' }}>{charge.charge_name}</Typography>
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', pt: 2 }} justifyContent='flex-end'>
              {charge.amounts.subscription_fee && <Typography>${charge.amounts.subscription_fee}</Typography>}
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', '@media (min-width: 600px)': { pr: '25px' }, pt: 2 }} justifyContent='flex-end'>
              {charge.amounts.adhoc_fee && <Typography>${charge.amounts.adhoc_fee}</Typography>}
            </Grid>
          </Fragment>
        })}
      </Grid>
      <Divider sx={{ borderColor: '#989898' }} />
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={6} sx={{ pl: 1 }}>
            <Typography sx={{ fontSize: '16px!important', fontWeight: 700, '@media (min-width: 600px)': { display: 'inline' } }}>Total Charges </Typography>
            <Typography variant='subtitle1' sx={{ '@media (min-width: 600px)': { display: 'inline' } }}>(ex.GST)</Typography>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex' }} justifyContent='flex-end'>
            <Grid container>
              <Grid item xs={12} md={12} display='flex' justifyContent='flex-end' alignItems='center'><Typography sx={{ fontWeight: 700, fontSize: '20px' }}>${formatPrice(totalSubscription)}</Typography></Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', '@media (min-width: 600px)': { pr: '25px' }, justifyContent: 'flex-end' }} >
            <Grid container>
              <Grid item xs={12} md={12} display='flex' justifyContent='flex-end' alignItems='center'><Typography sx={{ fontWeight: 700, fontSize: '20px' }}>${formatPrice(totalOnceoff)}</Typography></Grid>
            </Grid>
          </Grid>
        </Grid>
    </Paper>
  </Grid>
}

export default VerificationChangeItem