import { Outlet } from 'react-router-dom'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Grid, Box, Typography, Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import headerlogo from '../../assets/images/alltel-logo.svg'
import PhoneIcon from '@mui/icons-material/Phone';
import "./Verification.css"

declare module "@mui/material/styles" {
  interface TypographyVariants {
    error: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    error?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    error: true;
  }
}

const theme = createTheme(
  {
    components: {
      MuiPaper: {
        styleOverrides: {
          outlined: {
            border: '1px solid #C4C4C4',
            borderRadius: "10px",
            boxShadow: "0px 0px 6px #0000001A"
          },
        }
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            background: 'transparent linear-gradient(90deg, #e43346 0%, #ff7626 100%) 0% 0% no-repeat padding-box',
            borderRadius: '25px',
            fontFamily: 'Archivo',
            fontSize: '17px',
            textTransform: 'none'
          },
          sizeLarge: {
            paddingTop: '11px',
            paddingBottom: '11px'
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          h4: {
            fontWeight: '650',
            '@media (min-width:0px)': {
              fontSize: '20px',
            },
            '@media (min-width:900px)': {
              fontSize: '32px',
            }
          },
          h6: {
            fontWeight: '550',
            '@media (min-width:0px)': {
              fontSize: '15px',
            },
            '@media (min-width:600px)': {
              fontSize: '23px',
            },
          },
          subtitle2: {
            fontWeight: '600',
            color: "#242424",
            '@media (min-width:0px)': {
              fontSize: '15px',
            },
            '@media (min-width:600px)': {
              fontSize: '16px',
            },
          },
          subtitle1: {
            color: "#242424",
            '@media (min-width:0px)': {
              fontSize: '9px',
            },
            '@media (min-width:600px)': {
              fontSize: '18px',
            },
          },
          root: {
            fontFamily: ['Archivo'].join(','),
            color: "#242424",
            '@media (min-width:0px)': {
              fontSize: '15px',
            },
            '@media (min-width:600px)': {
              fontSize: '16px',
            },
          }
        }
      }
    }
  }
);

theme.typography.error = {
  color: '#B31B1B!important',
  fontSize: '15px!important',
  [theme.breakpoints.down('sm')]: {
    fontSize: '11px!important',
    
  },
}

const LinkStyle = {
  color: '#989898',
  textDecoration: 'none',
  fontSize: '15px',
  fontWeight: '450',
}

const VerificationLayout = () => {
  return <ThemeProvider theme={theme}>
    <Grid container maxWidth="xl" sx={{ margin: 'auto', '@media (min-width: 600px)': { mt: '30px' }, '@media (min-width: 0px)': { mt: '10px' } }}>
      <Grid item xs={6} sx={{ pl: 2 }}><img alt="logo" src={headerlogo} width='100px' /></Grid>
      <Grid item xs={6} sx={{ display: 'flex', pr: 2 }} justifyContent="flex-end" alignItems='center'>
        <Button onClick={() => { window.location.href = `tel:+611300255835`; }} startIcon={<PhoneIcon sx={{ color: '#2096EF' }} />}><Typography sx={{ fontWeight: 'bold' }}>1300 255 835</Typography></Button>
      </Grid>
      <Grid item xs={12} sx={{ mt: '7px' }}>
        <Box sx={{ background: 'transparent linear-gradient(85deg, #0185FE 0%, #00D8FF 100%) 0% 0% no-repeat padding-box', p: 7, alignItems: 'center', textAlign: 'center' }}>
          <Typography variant='h4' sx={{ color: '#ffffff' }}>Verify your order</Typography>
          <Typography sx={{ color: '#ffffff', mt: '7px', '@media (min-width: 0px)': { fontSize: '12px' }, '@media (min-width: 600px)': { fontSize: '16px' }  }}>Please check the details of your order and accept the Terms and Conditions to confirm your order for processing.
</Typography>

        </Box>
      </Grid>
      <Grid item xs={12}><Outlet /></Grid>
      <Grid item xs={12}>
        <Grid container sx={{ p: 7 }}>
          <Grid item xs={12}>
            <Stack direction='row' spacing={5} sx={{ justifyContent: 'center' }}>
              <Link target='_terms' to="https://www.alltel.com.au/terms-conditions" style={LinkStyle}>Website Terms and Conditions</Link>
              <Link target='_contactus' to="https://www.alltel.com.au/contact-us" style={LinkStyle}>Contact Us</Link>
              <Link target='_aboutus' to="https://www.alltel.com.au/about-us" style={LinkStyle}>About Us</Link>
              <Link target='_policies' to="https://www.alltel.com.au/policies" style={LinkStyle}>Policies</Link>
            </Stack></Grid>
          <Grid item xs={12} sx={{mt: 3}}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography sx={{color: '#989898', fontSize: '13px!important'}}>© 2007-{new Date().getFullYear()} Alltel Pty Ltd (ABN 95 126 744 116) All rights reserved.</Typography>
            </Box>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  </ThemeProvider>
}

export default VerificationLayout