import type { orderItem as OrderItemType } from './VerificationType'
import { Grid, Box, Typography, Divider, Paper, Button } from '@mui/material'
import { Fragment, useState } from 'react'
import { formatPrice } from '../../helpers/format'
import InboundConfig from './InboundConfig'
import ProfessionalRecordingConfig from './ProfessionalRecordingConfig'
import LiveAnsweringConfig from './LiveAnsweringConfig'
import AnalytixConfig from './AnalytixConfig'
import CommscodeConfig from './CommscodeConfig'
import BusinessIntroConfig from './BusinessIntroConfig'
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Collapse from '@mui/material/Collapse';

const VerificationNewItem = ({ orderItem }: { orderItem: OrderItemType }) => {
  const [showConfig, setShowConfig] = useState(false)

  let totalSubscription: number = 0
  let totalOnceoff: number = 0

  const provisioningModule = [
    {
      module: 'inbound1300',
      page: <InboundConfig orderItem={orderItem}/>
    },
    {
      module: 'inbound1800',
      page: <InboundConfig orderItem={orderItem}/>
    },
    {
      module: 'inbound13',
      page: <InboundConfig orderItem={orderItem}/>
    },
    {
      module: 'professionalrecording',
      page: <ProfessionalRecordingConfig orderItem={orderItem}/>
    },
    {
      module: 'rctmc',
      page: <LiveAnsweringConfig orderItem={orderItem}/>
    },
    {
      module: 'conciergetmc',
      page: <LiveAnsweringConfig orderItem={orderItem}/>
    },
    {
      module: 'analytix',
      page: <AnalytixConfig orderItem={orderItem}/>
    },
    {
      module: 'commscodev2e',
      page: <CommscodeConfig orderItem={orderItem}/>
    },
    {
      module: 'commscodelc',
      page: <CommscodeConfig orderItem={orderItem}/>
    },
    {
      module: 'businessintro',
      page: <BusinessIntroConfig orderItem={orderItem}/>
    },
    
  ]

  const module = provisioningModule.filter(config => {
    return config.module === orderItem.provisioning_module
  })

  orderItem.product_charges.charges.forEach(charge => {
    if (charge.amounts.subscription_fee) {
      totalSubscription += +charge.amounts.subscription_fee
    }
    if (charge.amounts.setup_fee) {
      totalOnceoff += +charge.amounts.setup_fee
    }
    if (charge.amounts.adhoc_fee) {
      totalOnceoff += +charge.amounts.adhoc_fee
    }
    charge.discounts.forEach(discount => {
      if (discount.charge_type === 'SETUP_FEE') {
        totalOnceoff -= +discount.discount_amount
      }
      if (discount.charge_type === 'SUBSCRIPTION_FEE') {
        totalSubscription -= +discount.discount_amount
      }
    })
  });

  const baseCharges = orderItem.product_charges.charges.filter(charge => {
    return charge.charge_name === 'Base charges'
  })

  const baseSubscriptionDiscount = baseCharges[0].discounts.filter(discount => {
    return discount.charge_type === 'SUBSCRIPTION_FEE'
  })

  const baseSetupDiscount = baseCharges[0].discounts.filter(discount => {
    return discount.charge_type === 'SETUP_FEE'
  })

  const otherCharges = orderItem.product_charges.charges.filter(charge => {
    return charge.charge_name !== 'Base charges'
  })

  const toggleShow = () => {
    setShowConfig(prev => !prev)
  }
  return <Grid item xs={12} sx={{ mt: '10px', mb: '40px' }} key={orderItem.order_item_id}>
    <Box sx={{ zIndex: 1000, position: 'relative' }}>
      <Paper variant='outlined' sx={{ backgroundColor: 'white' }} style={{ zIndex: 'tooltip' }}>
        <Grid container sx={{ p: 1 }}>
          <Grid item xs={6} sx={{ display: 'flex', pt: 1, pl: 2 }}>
            <Typography variant='h6' sx={{ color: '#0185FE' }}>{orderItem.category_name}</Typography>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', pt: 1 }} alignItems='flex-end' justifyContent='flex-end'>
            <Typography variant="subtitle1" sx={{ color: '#0185FE' }}>SUBSCRIPTION</Typography>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', pt: 1, '@media (min-width: 600px)': { pr: '25px' } }} alignItems='flex-end' justifyContent='flex-end'>
            <Typography variant="subtitle1" sx={{ color: '#0185FE' }}>ONCE-OFF</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: '#989898' }} />
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={6} sx={{ pl: 1 }}>
            <Typography sx={{ fontWeight: 700, display: 'inline' }}>{orderItem.plan_name}</Typography>
            {baseCharges[0].recurring_month && <Typography sx={{ display: 'inline' }}> - {baseCharges[0].recurring_month} month(s) Subscription</Typography>}
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex' }} justifyContent='flex-end'>
            {baseCharges[0].amounts.subscription_fee && <Typography>${baseCharges[0].amounts.subscription_fee}</Typography>
            }
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', '@media (min-width: 600px)': { pr: '25px' } }} justifyContent='flex-end'>
          </Grid>
          {baseCharges[0].amounts.setup_fee && <>
            <Grid item xs={12} sx={{ pt: 2 }}><Divider /></Grid>
            <Grid item xs={6} sx={{ pl: 1, pt: 2 }}>
              <Typography sx={{ display: 'inline' }}>Set up fee</Typography>
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', pt: 2 }} justifyContent='flex-end'></Grid>
            <Grid item xs={3} sx={{ display: 'flex', '@media (min-width: 600px)': { pr: '25px' }, pt: 2 }} justifyContent='flex-end' alignItems='flex-end'><Typography>${baseCharges[0].amounts.setup_fee}</Typography></Grid>
          </>}

          {baseCharges[0].discounts.length > 0 && <>
            <Grid item xs={12} sx={{ pt: 2 }}><Divider /></Grid>
            <Grid item xs={6} sx={{ pl: 1, pt: 2 }}>
              <Typography sx={{ display: 'inline', color: '#cf0000'}}>Discount</Typography>
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', pt: 2 }} justifyContent='flex-end' alignItems='flex-end'>
              {baseSubscriptionDiscount.length > 0 && <Typography sx={{ color: '#cf0000' }}>-${baseSubscriptionDiscount[0].discount_amount}</Typography>}
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', '@media (min-width: 600px)': { pr: '25px' }, pt: 2 }} justifyContent='flex-end' alignItems='flex-end'>
              {baseSetupDiscount.length > 0 && <Typography sx={{ color: '#cf0000' }}>-${baseSetupDiscount[0].discount_amount}</Typography>}
            </Grid>

          </>}

          {otherCharges.map(charge => {
            return <Fragment key={charge.order_item_charge_id}>
              <Grid item xs={12} sx={{ pt: 2 }}><Divider /></Grid>
              <Grid item xs={6} sx={{ pl: 1, pt: 2 }}>
                <Typography sx={{ display: 'inline' }}>{charge.charge_name}</Typography>
              </Grid>
              <Grid item xs={3} sx={{ display: 'flex', pt: 2 }} justifyContent='flex-end'>
                {charge.amounts.subscription_fee && <Typography>${charge.amounts.subscription_fee}</Typography>}
              </Grid>
              <Grid item xs={3} sx={{ display: 'flex', '@media (min-width: 600px)': { pr: '25px' }, pt: 2 }} justifyContent='flex-end'>
                {charge.amounts.adhoc_fee && <Typography>${charge.amounts.adhoc_fee}</Typography>}
              </Grid>
            </Fragment>
          })}

        </Grid>
        <Divider sx={{ borderColor: '#989898' }} />
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={6} sx={{ pl: 1 }}>
            <Typography sx={{ fontSize: '16px!important', fontWeight: 700, '@media (min-width: 600px)': { display: 'inline' } }}>Total Charges </Typography>
            <Typography variant='subtitle1' sx={{ '@media (min-width: 600px)': { display: 'inline' } }}>(ex.GST)</Typography>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex' }} justifyContent='flex-end'>
            <Grid container>
              <Grid item xs={12} md={12} display='flex' justifyContent='flex-end' alignItems='center'><Typography sx={{ fontWeight: 700, fontSize: '20px' }}>${formatPrice(totalSubscription)}</Typography></Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', '@media (min-width: 600px)': { pr: '25px' }, justifyContent: 'flex-end' }} >
            <Grid container>
              <Grid item xs={12} md={12} display='flex' justifyContent='flex-end' alignItems='center'><Typography sx={{ fontWeight: 700, fontSize: '20px' }}>${formatPrice(totalOnceoff)}</Typography></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
    {module.length > 0 && <Box sx={{ zIndex: '1', mt: -1, position: 'relative'}}>
      <Box sx={{backgroundColor: '#E1F5FFBF', p: 2, pt: 2, pb: 1 }}><Button onClick={toggleShow} endIcon={showConfig ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        startIcon={<SettingsIcon sx={{ color: 'black' }} />}>
        <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>CONFIGURATION
        </Typography></Button>
      </Box>
      <Collapse in={showConfig}>
      <Box sx={{ backgroundColor: '#F0FAFF', mt: '3px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', p: 3  }}>
        {module[0].page}
      </Box>
      </Collapse>
    </Box>
    }

  </Grid>
}

export default VerificationNewItem